import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
// import CreateEditContactos from '../views/AdministrarContactos/CreateEditContactos.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'PaginaInicio',
    component: () => import("../views/PaginaInicio.vue"),
    meta: {
      requiresAuth: true
    }
  },

  // Alarmas
  {
    path: '/Botoneras',
    name: 'botoneras',
    component: () => import('../views/AdministrarBotoneras/Botoneras.vue'),
    meta: {
      requiresAuth: true
    }

  },
    {
      path: '/createEditBotoneras/:item/:accion',
      name: 'createEditBotoneras',
      component: () => import('../views/AdministrarBotoneras/CreateEditBotoneras.vue'),
      meta: {
        requiresAuth: true
      }

    },

  {
    path: '/Dashboard',
    name: 'dashboard',
    component: () => import('../views/DashboardMenu.vue'),
    meta: {
      requiresAuth: true
    }

  },
  {
    path: '/Contactos',
    name: 'Contactos',
    component: () => import('../views/AdministrarContactos/TiposContactos.vue'),
    meta: {
      requiresAuth: true
    }

  },
    // {
    //   path: '/createEditContactos',
    //   name: 'createEditContactos',
    //   component: () => import('../views/AdministrarContactos/CreateEditContactos.vue')
    // },

  {
    path: '/Servicios',
    name: 'Servicios',
    component: () => import('../views/AdministrarServicios/TiposServicios.vue'),
    meta: {
      requiresAuth: true
    }

  },
    // {
    //   path: '/createEditServicios',
    //   name: 'createEditServicios',
    //   component: () => import('../views/AdministrarServicios/CreateEditServicios.vue')
    // },

  {
    path: '/Privacidad',
    name: 'Privacidad',
    component: () => import('../views/AdministrarPrivacidad/TiposPrivacidad.vue'),
    meta: {
      requiresAuth: true
    }

  },
    // {
    //   path: '/createEditPrivacidad',
    //   name: 'createEditPrivacidad',
    //   component: () => import('../views/AdministrarPrivacidad/CreateEditPrivacidad.vue')
    // },

  {
    path: '/Operaciones',
    name: 'Operaciones',
    component: () => import('../views/AdministrarOperaciones/TiposOperaciones.vue'),
    meta: {
      requiresAuth: true
    }

  },
    // {
    //   path: '/createEditOperaciones',
    //   name: 'createEditOperaciones',
    //   component: () => import('../views/AdministrarOperaciones/CreateEditOperaciones.vue')
    // },

    {
      path: '/avisosGenerados',
      name: 'AvisosGenerados',
      component: () => import('../views/AdministrarAlarmasGeneradas/index.vue'),
      meta: {
        requiresAuth: true
      }

    },
    {
      path: '/detalleAviso/:id',
      name: 'DetalleAviso',
      component: () => import('../views/AdministrarAlarmasGeneradas/Detalle.vue'),
      // meta: {
      //   requiresAuth: true
      // }

    },
    {
      path: '/agregarOperacionAviso/:id',
      name: 'AgregarOperacion',
      component: () => import('../views/AdministrarAlarmasGeneradas/AgregarOperacion.vue'),
      meta: {
        requiresAuth: true
      }

      // beforeEnter(to, from, next){
      //   if(store.state.user.authorizedTo.includes('Agregar operación aviso')){
      //     next();
      //   } else {
      //     router.push({name: 'PaginaInicio'})
      //   }
      // }
    },
    {
      path: '/mapaAvisos',
      name: 'MapaAvisos',
      component: () => import('../views/MapaAlarmas/index.vue')
    },


  {
    path: '/UserAdmin',
    name: 'userAdmin',
    component: () => import('../views/AdministrarUsuarios/UsuariosAdmin.vue'),
    meta: {
      requiresAuth: true
    }

  },

  {
    path: '/Estados',
    name: 'estados',
    component: () => import('../views/AdministrarEstados/Estados.vue'),
    meta: {
      requiresAuth: true
    }

  },

  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }

  {
    path: "/auth/:token/:usu_id",
    name: "Authenticate",
    props: true,
    component: () => import("../views/access/Authenticate.vue")
  },
  {
    path: "/notfound",
    name: "NotFound",
    component: () => import("../views/access/NotFound.vue")
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: () => import("../views/access/Unauthorized.vue")
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/Logout.vue"),
  },
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  //valida si la route existe
  if (to.matched.length > 0) {//si existe

    //verifica si la route requiere login
    if(to.matched.some(record => record.meta.requiresAuth)) {//si requiere login
      console.log('login requerido');
      if (store.getters.isLoggedIn) {
        console.log('esta logueado y avanza');
        next()
        return
      } else {
        console.log('no esta logueado no puede avanzar');
        // router.push({
        //   name: 'Unauthorized'
        // });
        // Redirige al mapa publico
        router.push({
          name: 'MapaAvisos'
        })
      }
    } else {//sino requiere login
      console.log('sin login requerido');
      next()
    }
  } else {//sino existe
    router.push({
      name: 'NotFound'
    });
  }
})

export default router
