<template>
  <v-card height="100%" width="256" class="mx-auto card-nav">
    <v-navigation-drawer permanent>
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img :src="logo">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ empresa }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-list dense>
        <div v-for="( item, index ) in itemsMenu" :key="'mn' + index">
          <v-tooltip bottom v-if="item.submenus.length == 0" color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item exact router :to="item.to" v-bind="attrs" v-on="on" color="primary">
                <v-list-item-action class="pa-0">
                  <v-icon class="primary--text text--darken-1">{{ item.icon }}</v-icon>
                </v-list-item-action>

                <v-list-item-content class="primary--text text--darken-1">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>

          <div v-else>
            <v-list-group v-model="item.active">

              <template v-slot:activator>
                <v-tooltip right bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-action class="pa-0" v-bind="attrs" v-on="on">
                      <v-icon class="primary--text text--darken-1">{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content class="primary--text text--darken-1" v-bind="attrs" v-on="on">
                      <v-list-item-title v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <span>{{ item.title }}</span>
                </v-tooltip>
              </template>

              <v-list-item v-for="( option, index ) in item.submenus" :key="'smn' + index" router exact :to="option.to">
                <v-tooltip right bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-action v-bind="attrs" v-on="on">
                      <v-icon x-small class="primary--text text--darken-1">{{ option.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content class="primary--text text--darken-1" v-bind="attrs" v-on="on">
                      <v-list-item-title v-text="option.title"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <span>{{ option.title }}</span>
                </v-tooltip>
              </v-list-item>
            </v-list-group>
          </div>
        </div>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      // logo: require('@/assets/logo_yo.png'),
      logo: 'https://yo.geopop.org/' + this.$store.state.user.Emp_Imagen,
      empresa: this.$store.state.user.Emp_Nombre,
      right: null,
      itemsMenu: [
        { title: 'Inicio', icon: 'mdi-home', to: '/', submenus: [] },
        { title: 'Configuración Básica', icon: 'mdi-view-dashboard', to: '/Dashboard', submenus: [] },
        { title: 'Configuración de Avisos', icon: 'mdi-access-point', to: '/Botoneras', submenus: [] },
        { title: 'Avisos Recibidos', icon: 'mdi-alarm-light', to: '', submenus: [
          { title: 'Consulta y Operaciones de Avisos', icon: 'mdi-access-point', to: '/avisosGenerados', submenus: [] },
          { title: 'Mapa de Avisos', icon: 'mdi-map-marker-outline', to: '/mapaAvisos', submenus: [] },

        ] },
        // {
        //   title: 'Configuraciones', icon: 'mdi-access-point-check', to: '/EstadosAlarmas', submenus: [
        //     { title: 'Opcion1', to: '/questionnaire_group_destiny' },
        //     { title: 'Opcion2', to: '/questionnaire_group_destiny' },
        //     { title: 'Opcion3', to: '/questionnaire_group_destiny' },
        //     { title: 'Opcion4', to: '/questionnaire_group_destiny' },
        //   ]
        // },
        // { title: 'Instalaciones',
        //   icon: 'mdi-file-tree-outline',
        //   submenus:[
        //     { title: 'Administrar Instalaciones', to:'/installations'},
        //   ]
        // },
        // { title: 'Cuestionarios',
        //   icon: 'mdi-calendar-question',
        //   submenus:[
        //     { title: 'Administrar Cuestionarios', to:'/adminQuestionnaires'},
        //   ]
        // },
        // { title: 'Encuestas',
        //   icon: 'mdi-clipboard-list-outline',
        //   submenus:[
        //     { title: 'Administrar Encuestas', to:'/surveys'},
        //   ]
        // },
        // { title: 'Asignacion de Encuestas o Cuestionarios', icon: 'mdi-clipboard-account-outline', to: '/answers', submenus:[
        //     { title: 'Asignar Grupo de Usuarios/Instalaciones a Cuestionarios', to:'/questionnaire_group_destiny'},
        //     { title: 'Encuestas Asignadas a Encuestadores', to:'/roadmap'},
        //   ] 
        // },
        // { title: 'Encuestadores',
        //   icon: 'mdi-human-male-board-poll',
        //   submenus:[
        //     { title: 'Administrar Encuestadores', to:'/adminPollsters'},
        //     { title: 'Grupos de Encuestadores', to:'/groups_pollsters'},
        //   ]
        // },
        // { title: 'Usuarios',
        //   icon: 'mdi-account',
        //   submenus:[
        //     { title: ' Grupos de Usuarios', to:'/user_group'},
        //   ]
        // },
        // //{ title: 'Resultados de Cuestionarios/Encuestas', icon: 'mdi-forum', to: '/answers', submenus:[] },
        // { title: 'Configuración',
        //   icon: 'mdi-cog-outline',
        //   submenus:[
        //     { title: 'Tipos de Cuestionarios', to:'/typeQuestionnaires'},
        //     { title: 'Categorías de Preguntas', to:'/questionCategories'},
        //     { title: 'Tipos de Instalaciones', to:'/installationsTypes'},
        //     { title: 'Feriados', to:'/holidays'},
        //     { title: 'Canales', to:'/channels'},
        //   ]
        // },
        // { title: 'Control de Migración de Datos', icon: 'mdi-file', to: '/imports', submenus:[] },
      ]
    }
  },
  beforeMount() {
    console.log('User ', this.$store.state.user);
  }
}
</script>

<style>
.card-nav .mdi-chevron-down::before {
  color: #4e39de;
}
</style>
