<template>
    <v-row class="pr-0 align-center justify-space-between">
      <v-col cols="2">
        <v-container fluid class="d-none d-md-flex d-lg-flex">
          <v-img :src="logo"
                  alt="logo"
                  max-width="120"
                  class="ml-2"
                >
          </v-img>
        </v-container>
      </v-col>
  
      <v-col cols="10" class="d-flex align-center justify-end pr-0">
        <v-btn large class="mr-1" icon dark>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
  
        <v-btn large class="mr-1" icon dark>
          <v-icon>mdi-bell</v-icon>
        </v-btn>
  
        <v-menu offset-y open-on-hover v-if="$store.state.user?.name">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-avatar color="orange" size="30">
                  <!-- <v-img :src="perfil"
                      alt="perfil"
                  ></v-img> -->
                  <span>{{ $store.state.user.name[0].toUpperCase() }}</span>
              </v-avatar>
              <span class="ml-1 white--text">{{ $store.state.user.name }}</span>
              <v-btn icon>
                <v-icon class="title-menu">mdi-chevron-down</v-icon>
              </v-btn>
            </div>
          </template>
  
          <v-list dense>
            <v-list-item-group color="primary">
              <v-list-item link v-for="(item, i) in items" :key="i" :to="item.to" >
                  <v-list-item-icon>
                      <v-icon>
                          {{item.icon}}
                      </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                      <v-list-item-title>
                          <span>{{ item.title }}</span>
                      </v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </template>
  
  <script>
  
  
  export default {
    props: ['drawer'],
    name: "HeaderApp",
    data() {
      return {
        logo: require('@/assets/yo_white.png'),
        perfil: require('@/assets/perfil/laura.jpg'),
        items: [
          { title: "Cerrar Sesión", to: '/logout', icon: 'mdi-logout-variant' }
        ],
      };
    },
    computed:{
      userName() {
        return this.$store.state.user.name;
      },
    },
    methods: {
    },
  };
  </script>
  
  