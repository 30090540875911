import store from '../store'
import axios from 'axios';

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

axios.interceptors.response.use(
function(response){
    //console.log('version', parseInt(response.headers.version));
    return (response) ? response : null;
}, function(error){
        console.log(error)
    if (error.response.status === 401) {
        setTimeout(function () {
            window.location.href = '/';
          }, 1000);
        return Promise.resolve();
    }else{
        return Promise.reject(error);
    }
});

axios.interceptors.request.use(function (config) {
    config.headers['usu-token'] = store.state.user.authToken; // test 422588688186121165396892813998 
    config.headers['usu-id'] = store.state.user.Usu_Id; // test 3061552
    config.headers['emp-id'] = store.state.user.Emp_Id; // test 4
    config.headers['Content-Type'] = 'application/json';
    return config;
}, function (err) {
    return Promise.reject(err);
});

export default axios;