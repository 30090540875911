import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import router from '../router'
// import quickMessage from './modules/quickMessage';
// import hardCode from './modules/hardCode';
//import moment from "moment";



Vue.use(Vuex)

const store = new Vuex.Store({
  // modules: { quickMessage, hardCode },
  plugins: [createPersistedState()],
  getters : {
    isLoggedIn: state => !!state.user.authToken,
    authStatus: state => state.user.loggedInStatus,
  },
  state: {
    snackbar:{
      show: false,
      message: '',
      color: 'green'
    },
    user: {
      Usu_Id: '',
      Emp_Id:'',
      Emp_Nombre: '',
      Emp_Imagen: '',
      name: '',
      loggedInStatus: false,
      authToken: '',
      roles: [],
      viewDirectoryDialog: false,
      // ! privosorio
      authorizedTo:
      [
        'Agregar operación aviso',
        'Crear botonera',
        'Editar botonera',
        'Inhabilitar botonera',
        'Ver servicios botonera',
        'Crear nuevo servicio',
        'Editar servicio',
        'Inhabilitar servicio',
        'Asociar gestores servicio',
        'Asociar contacto servicio',
        'Agregar canal comunicacion',
        'Editar canal comunicacion',
        'Eliminar canal comunicacion',
        'Agregar acceso rapido',
        'Editar acceso rapido',
        'Inhabilitar acceso rapido',
        'Agregar tipo privacidad',
        'Editar tipo privacidad',
        'Eliminar tipo privacidad',
        'Agregar tipo operacion',
        'Editar tipo operacion',
        'Eliminar tipo operacion',
        'Editar estado',
        'Eliminar estado',
      ],
      // ! end provisorio
    },
    rules: {
      min: v => v.length >= 8 || 'Min 8 caracteres',
      min1: v => v.length >= 1 || 'Este campo es Requerido',
      required: v => !!v || 'Este dato es Requerido',
      requiredMultiple: v => v.length > 0 || 'Este dato es Requerido',
    },
  },
  mutations: {
    setViewDirectoryDialog: (state, payload) =>{
      state.viewDirectoryDialog = payload;
    },
    addWebToken: function (state, data) {
      state.user.authToken = data.Use_Token;
      state.user.Usu_Id = data.Usu_Id;
      state.user.Emp_Id = data.Emp_Id;
      state.user.Emp_Nombre = data.Emp_Data.Emp_Nombre;
      state.user.Emp_Imagen = data.Emp_Data.Emp_Imagen;
      state.user.name = data.Usu_Nombre + ' ' + data.Usu_Apellido;
      state.user.loggedInStatus = true;
      state.user.roles = data.Roles.map(item => item.Rol_Nombre);
    },
    removeWebToken: function (state) {
      state.user.authToken = '';
      state.user.Usu_Id = '';
      state.user.Emp_Id = '';
      state.user.Emp_Nombre = '';
      state.user.Emp_Imagen = '';
      state.user.name = '';
      state.user.loggedInStatus = false;
      state.user.roles = [];
    },
  },
  actions: {
    login: function (context, data) {
      return Vue.prototype.$http.post(process.env.VUE_APP_LOGIN_URI + 'login/by-token', { Use_Token: data.token, Usu_Id: data.usu_id})

        .then(function (response) {

          if (response.data.status === 'ok') {
            context.commit('addWebToken', response.data.data); // pass the webtoken as payload to the mutation
            console.log(response.data.data);
            router.push({
                path: '/'
              });
          }
          return response.data;
        });
    },
    logout: function (context) {
      context.commit('removeWebToken');
      router.push({
          path: '/',
      });
    }
  }
})

export default store;